.datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 4px 8px;
  background: #fff;
  cursor: pointer; }

.datepicker {
  display: none;
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  direction: ltr; }
.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  color: #333;
  font-family: "Open Sans", "sans-serif";
  font-size: 13px;
  line-height: 18px; }
.datepicker.dropdown-menu th {
  padding: 4px 5px; }
.datepicker.dropdown-menu td {
  padding: 4px 5px; }
.datepicker table {
  border: 0;
  margin: 0; }
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer; }
.datepicker .active {
  color: white;
}
.datepicker td.active.day {
  background: #43AC6A; }
.datepicker td.active.year {
  background: #43AC6A; }
.datepicker td.old {
  color: #999; }
.datepicker td.new {
  color: #999; }
.datepicker td span.active {
  background: #43AC6A; }
.datepicker td.day.disabled {
  color: #eee; }
.datepicker th.active.day {
  background: #43AC6A; }
.datepicker th.active.year {
  background: #43AC6A; }
.datepicker th.date-switch {
  width: 145px; }
.datepicker th span.active {
  background: #43AC6A; }
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }
.datepicker.days div.datepicker-days {
  display: block; }
.datepicker.months div.datepicker-months {
  display: block; }
.datepicker.years div.datepicker-years {
  display: block; }
.datepicker thead tr:first-child th {
  cursor: pointer; }
.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent; }
.datepicker tfoot tr:first-child th {
  cursor: pointer; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }
.datepicker-rtl table tr td span {
  float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0; }
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px; }
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -6px;
  left: 7px; }

.datepicker > div {
  display: none; }

.datepicker-dropdown::before {
  display: none; }

.datepicker-dropdown::after {
  display: none; }

.datepicker-close {
  position: absolute;
  top: -30px;
  right: 0;
  width: 15px;
  height: 30px;
  padding: 0;
  display: none; }

.table-striped .datepicker table tr td {
  background-color: transparent; }
.table-striped .datepicker table tr th {
  background-color: transparent; }