@import "node_modules/select2/src/scss/core";
@import "colors";

/* */
.select2-container--focus .select2-selection {
  border-color: #999999 !important;
}
.select2-selection {
  &.select2-selection--single {
    height: 35px;
    padding-top: 3px;
    .select2-selection__arrow {
      height: 35px;
    }
  }
  border-color: #cccccc !important;

  border-radius: 0px !important;
  line-height: 25px !important;
}

.select2-container--default {
  .select2-search--inline .select2-search__field {
    padding: 0;
    margin-bottom: -5px;
    margin-top: 12px;
    height: 20px;
    padding-bottom: 1px;
  }
  .select2-selection--multiple .select2-selection__choice {
    margin-top: 11px;
    line-height: 18px;
    margin-bottom: -5px;
  }
}


//.select2-container {
//  li.select2-results__option:not([id]):after {
//    font-weight: 900;
//    color: green;
//    content: ' (Add To Inventory)';
//  }
//  li.select2-results__option--highlighted.select2-results__option:not([id]):after {
//    color: yellow;
//  }
//}

b[inventory] {
  color: $green;
}

.select2-results__option--highlighted {
  b[inventory] {
    color: $lyellow;
  }
}