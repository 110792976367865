$orange: #FC663D;
$green: #43AC6A;
$gray: #efefef;
$yellow: #FCaa3D;
$lyellow: #ffde0f;


$all-colors: (
  'orange':$orange,
  'green':$green,
  'gray':$gray,
  'yellow':$yellow,
  'lyellow':$lyellow
);

@each $label, $color in $all-colors {
  "b[#{$label}], i[#{$label}]" {
    color: $color !important;
  }
  ".#{$label}" {
    color: $color !important;
  }
  ".bg-#{$label}" {
    background: $color !important;
  }
}


/* Text/Foreground Colors */
.fg.gray     { color: $gray }
.fg.orange, .orange, .orange a,.pending {
  color:$orange;
}




.dashboard .view h2,
.dashboard .view h2 a {
  color: #FCaa3D;
  color: #D88870;
}


.skin {
  color: #D88870;
}

.px16 {
  font-size: 16px;
}

.active {
  color: $green;
}



span.contact-block {
  color: #6f6f6f;
  line-height: 1.4;
  margin-top: 5px;
  padding: 0 15px;
  border-left: 1px solid #DDD;
  box-sizing: border-box;
  display: block;
}

.alert-box {
  a,
  a:hover {
    color: inherit;
    text-decoration: underline;
  }
}

