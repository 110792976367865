@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon.eot?pztdgl');
  src:url('../fonts/icomoon.eot?#iefixpztdgl') format('embedded-opentype'),
  url('../fonts/icomoon.woff?pztdgl') format('woff'),
  url('../fonts/icomoon.ttf?pztdgl') format('truetype'),
  url('../fonts/icomoon.svg?pztdgl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e600";
}
.icon-home2:before {
  content: "\e601";
}
.icon-home3:before {
  content: "\e602";
}
.icon-office:before {
  content: "\e603";
}
.icon-newspaper:before {
  content: "\e604";
}
.icon-pencil:before {
  content: "\e605";
}
.icon-pencil2:before {
  content: "\e606";
}
.icon-quill:before {
  content: "\e607";
}
.icon-pen:before {
  content: "\e608";
}
.icon-blog:before {
  content: "\e609";
}
.icon-droplet:before {
  content: "\e60a";
}
.icon-paint-format:before {
  content: "\e60b";
}
.icon-image:before {
  content: "\e60c";
}
.icon-image2:before {
  content: "\e60d";
}
.icon-images:before {
  content: "\e60e";
}
.icon-camera:before {
  content: "\e60f";
}
.icon-music:before {
  content: "\e610";
}
.icon-headphones:before {
  content: "\e611";
}
.icon-play:before {
  content: "\e612";
}
.icon-film:before {
  content: "\e613";
}
.icon-camera2:before {
  content: "\e614";
}
.icon-dice:before {
  content: "\e615";
}
.icon-pacman:before {
  content: "\e616";
}
.icon-spades:before {
  content: "\e617";
}
.icon-clubs:before {
  content: "\e618";
}
.icon-diamonds:before {
  content: "\e619";
}
.icon-pawn:before {
  content: "\e61a";
}
.icon-bullhorn:before {
  content: "\e61b";
}
.icon-connection:before {
  content: "\e61c";
}
.icon-podcast:before {
  content: "\e61d";
}
.icon-feed:before {
  content: "\e61e";
}
.icon-book:before {
  content: "\e61f";
}
.icon-books:before {
  content: "\e620";
}
.icon-library:before {
  content: "\e621";
}
.icon-file:before {
  content: "\e622";
}
.icon-profile:before {
  content: "\e623";
}
.icon-file2:before {
  content: "\e624";
}
.icon-file3:before {
  content: "\e625";
}
.icon-file4:before {
  content: "\e626";
}
.icon-copy:before {
  content: "\e627";
}
.icon-copy2:before {
  content: "\e628";
}
.icon-copy3:before {
  content: "\e629";
}
.icon-paste:before {
  content: "\e62a";
}
.icon-paste2:before {
  content: "\e62b";
}
.icon-paste3:before {
  content: "\e62c";
}
.icon-stack:before {
  content: "\e62d";
}
.icon-folder:before {
  content: "\e62e";
}
.icon-folder-open:before {
  content: "\e62f";
}
.icon-tag:before {
  content: "\e630";
}
.icon-tags:before {
  content: "\e631";
}
.icon-barcode:before {
  content: "\e632";
}
.icon-qrcode:before {
  content: "\e633";
}
.icon-ticket:before {
  content: "\e634";
}
.icon-cart:before {
  content: "\e635";
}
.icon-cart2:before {
  content: "\e636";
}
.icon-cart3:before {
  content: "\e637";
}
.icon-coin:before {
  content: "\e638";
}
.icon-credit:before {
  content: "\e639";
}
.icon-calculate:before {
  content: "\e63a";
}
.icon-support:before {
  content: "\e63b";
}
.icon-phone:before {
  content: "\e63c";
}
.icon-phone-hang-up:before {
  content: "\e63d";
}
.icon-address-book:before {
  content: "\e63e";
}
.icon-notebook:before {
  content: "\e63f";
}
.icon-envelope:before {
  content: "\e640";
}
.icon-pushpin:before {
  content: "\e641";
}
.icon-location:before {
  content: "\e642";
}
.icon-location2:before {
  content: "\e643";
}
.icon-compass:before {
  content: "\e644";
}
.icon-map:before {
  content: "\e645";
}
.icon-map2:before {
  content: "\e646";
}
.icon-history:before {
  content: "\e647";
}
.icon-clock:before {
  content: "\e648";
}
.icon-clock2:before {
  content: "\e649";
}
.icon-alarm:before {
  content: "\e64a";
}
.icon-alarm2:before {
  content: "\e64b";
}
.icon-bell:before {
  content: "\e64c";
}
.icon-stopwatch:before {
  content: "\e64d";
}
.icon-calendar:before {
  content: "\e64e";
}
.icon-calendar2:before {
  content: "\e64f";
}
.icon-print:before {
  content: "\e650";
}
.icon-keyboard:before {
  content: "\e651";
}
.icon-screen:before {
  content: "\e652";
}
.icon-laptop:before {
  content: "\e653";
}
.icon-mobile:before {
  content: "\e654";
}
.icon-mobile2:before {
  content: "\e655";
}
.icon-tablet:before {
  content: "\e656";
}
.icon-tv:before {
  content: "\e657";
}
.icon-cabinet:before {
  content: "\e658";
}
.icon-drawer:before {
  content: "\e659";
}
.icon-drawer2:before {
  content: "\e65a";
}
.icon-drawer3:before {
  content: "\e65b";
}
.icon-box-add:before {
  content: "\e65c";
}
.icon-box-remove:before {
  content: "\e65d";
}
.icon-download:before {
  content: "\e65e";
}
.icon-upload:before {
  content: "\e65f";
}
.icon-disk:before {
  content: "\e660";
}
.icon-storage:before {
  content: "\e661";
}
.icon-undo:before {
  content: "\e662";
}
.icon-redo:before {
  content: "\e663";
}
.icon-flip:before {
  content: "\e664";
}
.icon-flip2:before {
  content: "\e665";
}
.icon-undo2:before {
  content: "\e666";
}
.icon-redo2:before {
  content: "\e667";
}
.icon-forward:before {
  content: "\e668";
}
.icon-reply:before {
  content: "\e669";
}
.icon-bubble:before {
  content: "\e66a";
}
.icon-bubbles:before {
  content: "\e66b";
}
.icon-bubbles2:before {
  content: "\e66c";
}
.icon-bubble2:before {
  content: "\e66d";
}
.icon-bubbles3:before {
  content: "\e66e";
}
.icon-bubbles4:before {
  content: "\e66f";
}
.icon-user:before {
  content: "\e670";
}
.icon-users:before {
  content: "\e671";
}
.icon-user2:before {
  content: "\e672";
}
.icon-users2:before {
  content: "\e673";
}
.icon-user3:before {
  content: "\e674";
}
.icon-user4:before {
  content: "\e675";
}
.icon-quotes-left:before {
  content: "\e676";
}
.icon-busy:before {
  content: "\e677";
}
.icon-spinner:before {
  content: "\e678";
}
.icon-spinner2:before {
  content: "\e679";
}
.icon-spinner3:before {
  content: "\e67a";
}
.icon-spinner4:before {
  content: "\e67b";
}
.icon-spinner5:before {
  content: "\e67c";
}
.icon-spinner6:before {
  content: "\e67d";
}
.icon-binoculars:before {
  content: "\e67e";
}
.icon-search:before {
  content: "\e67f";
}
.icon-zoomin:before {
  content: "\e680";
}
.icon-zoomout:before {
  content: "\e681";
}
.icon-expand:before {
  content: "\e682";
}
.icon-contract:before {
  content: "\e683";
}
.icon-expand2:before {
  content: "\e684";
}
.icon-contract2:before {
  content: "\e685";
}
.icon-key:before {
  content: "\e686";
}
.icon-key2:before {
  content: "\e687";
}
.icon-lock:before {
  content: "\e688";
}
.icon-lock2:before {
  content: "\e689";
}
.icon-unlocked:before {
  content: "\e68a";
}
.icon-wrench:before {
  content: "\e68b";
}
.icon-settings:before {
  content: "\e68c";
}
.icon-equalizer:before {
  content: "\e68d";
}
.icon-cog:before {
  content: "\e68e";
}
.icon-cogs:before {
  content: "\e68f";
}
.icon-cog2:before {
  content: "\e690";
}
.icon-hammer:before {
  content: "\e691";
}
.icon-wand:before {
  content: "\e692";
}
.icon-aid:before {
  content: "\e693";
}
.icon-bug:before {
  content: "\e694";
}
.icon-pie:before {
  content: "\e695";
}
.icon-stats:before {
  content: "\e696";
}
.icon-bars:before {
  content: "\e697";
}
.icon-bars2:before {
  content: "\e698";
}
.icon-gift:before {
  content: "\e699";
}
.icon-trophy:before {
  content: "\e69a";
}
.icon-glass:before {
  content: "\e69b";
}
.icon-mug:before {
  content: "\e69c";
}
.icon-food:before {
  content: "\e69d";
}
.icon-leaf:before {
  content: "\e69e";
}
.icon-rocket:before {
  content: "\e69f";
}
.icon-meter:before {
  content: "\e6a0";
}
.icon-meter2:before {
  content: "\e6a1";
}
.icon-dashboard:before {
  content: "\e6a2";
}
.icon-hammer2:before {
  content: "\e6a3";
}
.icon-fire:before {
  content: "\e6a4";
}
.icon-lab:before {
  content: "\e6a5";
}
.icon-magnet:before {
  content: "\e6a6";
}
.icon-remove:before {
  content: "\e6a7";
}
.icon-remove2:before {
  content: "\e6a8";
}
.icon-briefcase:before {
  content: "\e6a9";
}
.icon-airplane:before {
  content: "\e6aa";
}
.icon-truck:before {
  content: "\e6ab";
}
.icon-road:before {
  content: "\e6ac";
}
.icon-accessibility:before {
  content: "\e6ad";
}
.icon-target:before {
  content: "\e6ae";
}
.icon-shield:before {
  content: "\e6af";
}
.icon-lightning:before {
  content: "\e6b0";
}
.icon-switch:before {
  content: "\e6b1";
}
.icon-powercord:before {
  content: "\e6b2";
}
.icon-signup:before {
  content: "\e6b3";
}
.icon-list:before {
  content: "\e6b4";
}
.icon-list2:before {
  content: "\e6b5";
}
.icon-numbered-list:before {
  content: "\e6b6";
}
.icon-menu:before {
  content: "\e6b7";
}
.icon-menu2:before {
  content: "\e6b8";
}
.icon-tree:before {
  content: "\e6b9";
}
.icon-cloud:before {
  content: "\e6ba";
}
.icon-cloud-download:before {
  content: "\e6bb";
}
.icon-cloud-upload:before {
  content: "\e6bc";
}
.icon-download2:before {
  content: "\e6bd";
}
.icon-upload2:before {
  content: "\e6be";
}
.icon-download3:before {
  content: "\e6bf";
}
.icon-upload3:before {
  content: "\e6c0";
}
.icon-globe:before {
  content: "\e6c1";
}
.icon-earth:before {
  content: "\e6c2";
}
.icon-link:before {
  content: "\e6c3";
}
.icon-flag:before {
  content: "\e6c4";
}
.icon-attachment:before {
  content: "\e6c5";
}
.icon-eye:before {
  content: "\e6c6";
}
.icon-eye-blocked:before {
  content: "\e6c7";
}
.icon-eye2:before {
  content: "\e6c8";
}
.icon-bookmark:before {
  content: "\e6c9";
}
.icon-bookmarks:before {
  content: "\e6ca";
}
.icon-brightness-medium:before {
  content: "\e6cb";
}
.icon-brightness-contrast:before {
  content: "\e6cc";
}
.icon-contrast:before {
  content: "\e6cd";
}
.icon-star:before {
  content: "\e6ce";
}
.icon-star2:before {
  content: "\e6cf";
}
.icon-star3:before {
  content: "\e6d0";
}
.icon-heart:before {
  content: "\e6d1";
}
.icon-heart2:before {
  content: "\e6d2";
}
.icon-heart-broken:before {
  content: "\e6d3";
}
.icon-thumbs-up:before {
  content: "\e6d4";
}
.icon-thumbs-up2:before {
  content: "\e6d5";
}
.icon-happy:before {
  content: "\e6d6";
}
.icon-happy2:before {
  content: "\e6d7";
}
.icon-smiley:before {
  content: "\e6d8";
}
.icon-smiley2:before {
  content: "\e6d9";
}
.icon-tongue:before {
  content: "\e6da";
}
.icon-tongue2:before {
  content: "\e6db";
}
.icon-sad:before {
  content: "\e6dc";
}
.icon-sad2:before {
  content: "\e6dd";
}
.icon-wink:before {
  content: "\e6de";
}
.icon-wink2:before {
  content: "\e6df";
}
.icon-grin:before {
  content: "\e6e0";
}
.icon-grin2:before {
  content: "\e6e1";
}
.icon-cool:before {
  content: "\e6e2";
}
.icon-cool2:before {
  content: "\e6e3";
}
.icon-angry:before {
  content: "\e6e4";
}
.icon-angry2:before {
  content: "\e6e5";
}
.icon-evil:before {
  content: "\e6e6";
}
.icon-evil2:before {
  content: "\e6e7";
}
.icon-shocked:before {
  content: "\e6e8";
}
.icon-shocked2:before {
  content: "\e6e9";
}
.icon-confused:before {
  content: "\e6ea";
}
.icon-confused2:before {
  content: "\e6eb";
}
.icon-neutral:before {
  content: "\e6ec";
}
.icon-neutral2:before {
  content: "\e6ed";
}
.icon-wondering:before {
  content: "\e6ee";
}
.icon-wondering2:before {
  content: "\e6ef";
}
.icon-point-up:before {
  content: "\e6f0";
}
.icon-point-right:before {
  content: "\e6f1";
}
.icon-point-down:before {
  content: "\e6f2";
}
.icon-point-left:before {
  content: "\e6f3";
}
.icon-warning:before {
  content: "\e6f4";
}
.icon-notification:before {
  content: "\e6f5";
}
.icon-question:before {
  content: "\e6f6";
}
.icon-info:before {
  content: "\e6f7";
}
.icon-info2:before {
  content: "\e6f8";
}
.icon-blocked:before {
  content: "\e6f9";
}
.icon-cancel-circle:before {
  content: "\e6fa";
}
.icon-checkmark-circle:before {
  content: "\e6fb";
}
.icon-spam:before {
  content: "\e6fc";
}
.icon-close:before {
  content: "\e6fd";
}
.icon-checkmark:before {
  content: "\e6fe";
}
.icon-checkmark2:before {
  content: "\e6ff";
}
.icon-spell-check:before {
  content: "\e700";
}
.icon-minus:before {
  content: "\e701";
}
.icon-plus:before {
  content: "\e702";
}
.icon-enter:before {
  content: "\e703";
}
.icon-exit:before {
  content: "\e704";
}
.icon-play2:before {
  content: "\e705";
}
.icon-pause:before {
  content: "\e706";
}
.icon-stop:before {
  content: "\e707";
}
.icon-backward:before {
  content: "\e708";
}
.icon-forward2:before {
  content: "\e709";
}
.icon-play3:before {
  content: "\e70a";
}
.icon-pause2:before {
  content: "\e70b";
}
.icon-stop2:before {
  content: "\e70c";
}
.icon-backward2:before {
  content: "\e70d";
}
.icon-forward3:before {
  content: "\e70e";
}
.icon-first:before {
  content: "\e70f";
}
.icon-last:before {
  content: "\e710";
}
.icon-previous:before {
  content: "\e711";
}
.icon-next:before {
  content: "\e712";
}
.icon-eject:before {
  content: "\e713";
}
.icon-volume-high:before {
  content: "\e714";
}
.icon-volume-medium:before {
  content: "\e715";
}
.icon-volume-low:before {
  content: "\e716";
}
.icon-volume-mute:before {
  content: "\e717";
}
.icon-volume-mute2:before {
  content: "\e718";
}
.icon-volume-increase:before {
  content: "\e719";
}
.icon-volume-decrease:before {
  content: "\e71a";
}
.icon-loop:before {
  content: "\e71b";
}
.icon-loop2:before {
  content: "\e71c";
}
.icon-loop3:before {
  content: "\e71d";
}
.icon-shuffle:before {
  content: "\e71e";
}
.icon-arrow-up-left:before {
  content: "\e71f";
}
.icon-arrow-up:before {
  content: "\e720";
}
.icon-arrow-up-right:before {
  content: "\e721";
}
.icon-arrow-right:before {
  content: "\e722";
}
.icon-arrow-down-right:before {
  content: "\e723";
}
.icon-arrow-down:before {
  content: "\e724";
}
.icon-arrow-down-left:before {
  content: "\e725";
}
.icon-arrow-left:before {
  content: "\e726";
}
.icon-arrow-up-left2:before {
  content: "\e727";
}
.icon-arrow-up2:before {
  content: "\e728";
}
.icon-arrow-up-right2:before {
  content: "\e729";
}
.icon-arrow-right2:before {
  content: "\e72a";
}
.icon-arrow-down-right2:before {
  content: "\e72b";
}
.icon-arrow-down2:before {
  content: "\e72c";
}
.icon-arrow-down-left2:before {
  content: "\e72d";
}
.icon-arrow-left2:before {
  content: "\e72e";
}
.icon-arrow-up-left3:before {
  content: "\e72f";
}
.icon-arrow-up3:before {
  content: "\e730";
}
.icon-arrow-up-right3:before {
  content: "\e731";
}
.icon-arrow-right3:before {
  content: "\e732";
}
.icon-arrow-down-right3:before {
  content: "\e733";
}
.icon-arrow-down3:before {
  content: "\e734";
}
.icon-arrow-down-left3:before {
  content: "\e735";
}
.icon-arrow-left3:before {
  content: "\e736";
}
.icon-tab:before {
  content: "\e737";
}
.icon-checkbox-checked:before {
  content: "\e738";
}
.icon-checkbox-unchecked:before {
  content: "\e739";
}
.icon-checkbox-partial:before {
  content: "\e73a";
}
.icon-radio-checked:before {
  content: "\e73b";
}
.icon-radio-unchecked:before {
  content: "\e73c";
}
.icon-crop:before {
  content: "\e73d";
}
.icon-scissors:before {
  content: "\e73e";
}
.icon-filter:before {
  content: "\e73f";
}
.icon-filter2:before {
  content: "\e740";
}
.icon-font:before {
  content: "\e741";
}
.icon-text-height:before {
  content: "\e742";
}
.icon-text-width:before {
  content: "\e743";
}
.icon-bold:before {
  content: "\e744";
}
.icon-underline:before {
  content: "\e745";
}
.icon-italic:before {
  content: "\e746";
}
.icon-strikethrough:before {
  content: "\e747";
}
.icon-omega:before {
  content: "\e748";
}
.icon-sigma:before {
  content: "\e749";
}
.icon-table:before {
  content: "\e74a";
}
.icon-table2:before {
  content: "\e74b";
}
.icon-insert-template:before {
  content: "\e74c";
}
.icon-pilcrow:before {
  content: "\e74d";
}
.icon-lefttoright:before {
  content: "\e74e";
}
.icon-righttoleft:before {
  content: "\e74f";
}
.icon-paragraph-left:before {
  content: "\e750";
}
.icon-paragraph-center:before {
  content: "\e751";
}
.icon-paragraph-right:before {
  content: "\e752";
}
.icon-paragraph-justify:before {
  content: "\e753";
}
.icon-paragraph-left2:before {
  content: "\e754";
}
.icon-paragraph-center2:before {
  content: "\e755";
}
.icon-paragraph-right2:before {
  content: "\e756";
}
.icon-paragraph-justify2:before {
  content: "\e757";
}
.icon-indent-increase:before {
  content: "\e758";
}
.icon-indent-decrease:before {
  content: "\e759";
}
.icon-newtab:before {
  content: "\e75a";
}
.icon-embed:before {
  content: "\e75b";
}
.icon-code:before {
  content: "\e75c";
}
.icon-console:before {
  content: "\e75d";
}
.icon-share:before {
  content: "\e75e";
}
.icon-mail:before {
  content: "\e75f";
}
.icon-mail2:before {
  content: "\e760";
}
.icon-mail3:before {
  content: "\e761";
}
.icon-mail4:before {
  content: "\e762";
}
.icon-google:before {
  content: "\e763";
}
.icon-googleplus:before {
  content: "\e764";
}
.icon-googleplus2:before {
  content: "\e765";
}
.icon-googleplus3:before {
  content: "\e766";
}
.icon-googleplus4:before {
  content: "\e767";
}
.icon-google-drive:before {
  content: "\e768";
}
.icon-facebook:before {
  content: "\e769";
}
.icon-facebook2:before {
  content: "\e76a";
}
.icon-facebook3:before {
  content: "\e76b";
}
.icon-instagram:before {
  content: "\e76c";
}
.icon-twitter:before {
  content: "\e76d";
}
.icon-twitter2:before {
  content: "\e76e";
}
.icon-twitter3:before {
  content: "\e76f";
}
.icon-feed2:before {
  content: "\e770";
}
.icon-feed3:before {
  content: "\e771";
}
.icon-feed4:before {
  content: "\e772";
}
.icon-youtube:before {
  content: "\e773";
}
.icon-youtube2:before {
  content: "\e774";
}
.icon-vimeo:before {
  content: "\e775";
}
.icon-vimeo2:before {
  content: "\e776";
}
.icon-vimeo3:before {
  content: "\e777";
}
.icon-lanyrd:before {
  content: "\e778";
}
.icon-flickr:before {
  content: "\e779";
}
.icon-flickr2:before {
  content: "\e77a";
}
.icon-flickr3:before {
  content: "\e77b";
}
.icon-flickr4:before {
  content: "\e77c";
}
.icon-picassa:before {
  content: "\e77d";
}
.icon-picassa2:before {
  content: "\e77e";
}
.icon-dribbble:before {
  content: "\e77f";
}
.icon-dribbble2:before {
  content: "\e780";
}
.icon-dribbble3:before {
  content: "\e781";
}
.icon-forrst:before {
  content: "\e782";
}
.icon-forrst2:before {
  content: "\e783";
}
.icon-deviantart:before {
  content: "\e784";
}
.icon-deviantart2:before {
  content: "\e785";
}
.icon-steam:before {
  content: "\e786";
}
.icon-steam2:before {
  content: "\e787";
}
.icon-github:before {
  content: "\e788";
}
.icon-github2:before {
  content: "\e789";
}
.icon-github3:before {
  content: "\e78a";
}
.icon-github4:before {
  content: "\e78b";
}
.icon-github5:before {
  content: "\e78c";
}
.icon-wordpress:before {
  content: "\e78d";
}
.icon-wordpress2:before {
  content: "\e78e";
}
.icon-joomla:before {
  content: "\e78f";
}
.icon-blogger:before {
  content: "\e790";
}
.icon-blogger2:before {
  content: "\e791";
}
.icon-tumblr:before {
  content: "\e792";
}
.icon-tumblr2:before {
  content: "\e793";
}
.icon-yahoo:before {
  content: "\e794";
}
.icon-tux:before {
  content: "\e795";
}
.icon-apple:before {
  content: "\e796";
}
.icon-finder:before {
  content: "\e797";
}
.icon-android:before {
  content: "\e798";
}
.icon-windows:before {
  content: "\e799";
}
.icon-windows8:before {
  content: "\e79a";
}
.icon-soundcloud:before {
  content: "\e79b";
}
.icon-soundcloud2:before {
  content: "\e79c";
}
.icon-skype:before {
  content: "\e79d";
}
.icon-reddit:before {
  content: "\e79e";
}
.icon-linkedin:before {
  content: "\e79f";
}
.icon-lastfm:before {
  content: "\e7a0";
}
.icon-lastfm2:before {
  content: "\e7a1";
}
.icon-delicious:before {
  content: "\e7a2";
}
.icon-stumbleupon:before {
  content: "\e7a3";
}
.icon-stumbleupon2:before {
  content: "\e7a4";
}
.icon-stackoverflow:before {
  content: "\e7a5";
}
.icon-pinterest:before {
  content: "\e7a6";
}
.icon-pinterest2:before {
  content: "\e7a7";
}
.icon-xing:before {
  content: "\e7a8";
}
.icon-xing2:before {
  content: "\e7a9";
}
.icon-flattr:before {
  content: "\e7aa";
}
.icon-foursquare:before {
  content: "\e7ab";
}
.icon-foursquare2:before {
  content: "\e7ac";
}
.icon-paypal:before {
  content: "\e7ad";
}
.icon-paypal2:before {
  content: "\e7ae";
}
.icon-paypal3:before {
  content: "\e7af";
}
.icon-yelp:before {
  content: "\e7b0";
}
.icon-libreoffice:before {
  content: "\e7b1";
}
.icon-file-pdf:before {
  content: "\e7b2";
}
.icon-file-openoffice:before {
  content: "\e7b3";
}
.icon-file-word:before {
  content: "\e7b4";
}
.icon-file-excel:before {
  content: "\e7b5";
}
.icon-file-zip:before {
  content: "\e7b6";
}
.icon-file-powerpoint:before {
  content: "\e7b7";
}
.icon-file-xml:before {
  content: "\e7b8";
}
.icon-file-css:before {
  content: "\e7b9";
}
.icon-html5:before {
  content: "\e7ba";
}
.icon-html52:before {
  content: "\e7bb";
}
.icon-css3:before {
  content: "\e7bc";
}
.icon-chrome:before {
  content: "\e7bd";
}
.icon-firefox:before {
  content: "\e7be";
}
.icon-IE:before {
  content: "\e7bf";
}
.icon-opera:before {
  content: "\e7c0";
}
.icon-safari:before {
  content: "\e7c1";
}
.icon-IcoMoon:before {
  content: "\e7c2";
}
.icon-phone2:before {
  content: "\e7c3";
}
.icon-mobile3:before {
  content: "\e7c4";
}
.icon-mouse:before {
  content: "\e7c5";
}
.icon-directions:before {
  content: "\e7c6";
}
.icon-mail5:before {
  content: "\e7c7";
}
.icon-paperplane:before {
  content: "\e7c8";
}
.icon-pencil3:before {
  content: "\e7c9";
}
.icon-feather:before {
  content: "\e7ca";
}
.icon-paperclip:before {
  content: "\e7cb";
}
.icon-drawer4:before {
  content: "\e7cc";
}
.icon-reply2:before {
  content: "\e7cd";
}
.icon-reply-all:before {
  content: "\e7ce";
}
.icon-forward4:before {
  content: "\e7cf";
}
.icon-user5:before {
  content: "\e7d0";
}
.icon-users3:before {
  content: "\e7d1";
}
.icon-user-add:before {
  content: "\e7d2";
}
.icon-vcard:before {
  content: "\e7d3";
}
.icon-export:before {
  content: "\e7d4";
}
.icon-location3:before {
  content: "\e7d5";
}
.icon-map3:before {
  content: "\e7d6";
}
.icon-compass2:before {
  content: "\e7d7";
}
.icon-location4:before {
  content: "\e7d8";
}
.icon-target2:before {
  content: "\e7d9";
}
.icon-share2:before {
  content: "\e7da";
}
.icon-sharable:before {
  content: "\e7db";
}
.icon-heart3:before {
  content: "\e7dc";
}
.icon-heart4:before {
  content: "\e7dd";
}
.icon-star4:before {
  content: "\e7de";
}
.icon-star5:before {
  content: "\e7df";
}
.icon-thumbsup:before {
  content: "\e7e0";
}
.icon-thumbsdown:before {
  content: "\e7e1";
}
.icon-chat:before {
  content: "\e7e2";
}
.icon-comment:before {
  content: "\e7e3";
}
.icon-quote:before {
  content: "\e7e4";
}
.icon-house:before {
  content: "\e7e5";
}
.icon-popup:before {
  content: "\e7e6";
}
.icon-search2:before {
  content: "\e7e7";
}
.icon-flashlight:before {
  content: "\e7e8";
}
.icon-printer:before {
  content: "\e7e9";
}
.icon-bell2:before {
  content: "\e7ea";
}
.icon-link2:before {
  content: "\e7eb";
}
.icon-flag2:before {
  content: "\e7ec";
}
.icon-cog3:before {
  content: "\e7ed";
}
.icon-tools:before {
  content: "\e7ee";
}
.icon-trophy2:before {
  content: "\e7ef";
}
.icon-tag2:before {
  content: "\e7f0";
}
.icon-camera3:before {
  content: "\e7f1";
}
.icon-megaphone:before {
  content: "\e7f2";
}
.icon-moon:before {
  content: "\e7f3";
}
.icon-palette:before {
  content: "\e7f4";
}
.icon-leaf2:before {
  content: "\e7f5";
}
.icon-music2:before {
  content: "\e7f6";
}
.icon-music3:before {
  content: "\e7f7";
}
.icon-new:before {
  content: "\e7f8";
}
.icon-graduation:before {
  content: "\e7f9";
}
.icon-book2:before {
  content: "\e7fa";
}
.icon-newspaper2:before {
  content: "\e7fb";
}
.icon-bag:before {
  content: "\e7fc";
}
.icon-airplane2:before {
  content: "\e7fd";
}
.icon-lifebuoy:before {
  content: "\e7fe";
}
.icon-eye3:before {
  content: "\e7ff";
}
.icon-clock3:before {
  content: "\e800";
}
.icon-microphone:before {
  content: "\e801";
}
.icon-calendar3:before {
  content: "\e802";
}
.icon-bolt:before {
  content: "\e803";
}
.icon-thunder:before {
  content: "\e804";
}
.icon-droplet2:before {
  content: "\e805";
}
.icon-cd:before {
  content: "\e806";
}
.icon-briefcase2:before {
  content: "\e807";
}
.icon-air:before {
  content: "\e808";
}
.icon-hourglass:before {
  content: "\e809";
}
.icon-gauge:before {
  content: "\e80a";
}
.icon-language:before {
  content: "\e80b";
}
.icon-network:before {
  content: "\e80c";
}
.icon-key3:before {
  content: "\e80d";
}
.icon-battery:before {
  content: "\e80e";
}
.icon-bucket:before {
  content: "\e80f";
}
.icon-magnet2:before {
  content: "\e810";
}
.icon-drive:before {
  content: "\e811";
}
.icon-cup:before {
  content: "\e812";
}
.icon-rocket2:before {
  content: "\e813";
}
.icon-brush:before {
  content: "\e814";
}
.icon-suitcase:before {
  content: "\e815";
}
.icon-cone:before {
  content: "\e816";
}
.icon-earth2:before {
  content: "\e817";
}
.icon-keyboard2:before {
  content: "\e818";
}
.icon-browser:before {
  content: "\e819";
}
.icon-publish:before {
  content: "\e81a";
}
.icon-progress-3:before {
  content: "\e81b";
}
.icon-progress-2:before {
  content: "\e81c";
}
.icon-brogress-1:before {
  content: "\e81d";
}
.icon-progress-0:before {
  content: "\e81e";
}
.icon-sun:before {
  content: "\e81f";
}
.icon-sun2:before {
  content: "\e820";
}
.icon-adjust:before {
  content: "\e821";
}
.icon-code2:before {
  content: "\e822";
}
.icon-screen2:before {
  content: "\e823";
}
.icon-infinity:before {
  content: "\e824";
}
.icon-light-bulb:before {
  content: "\e825";
}
.icon-creditcard:before {
  content: "\e826";
}
.icon-database:before {
  content: "\e827";
}
.icon-voicemail:before {
  content: "\e828";
}
.icon-clipboard:before {
  content: "\e829";
}
.icon-cart4:before {
  content: "\e82a";
}
.icon-box:before {
  content: "\e82b";
}
.icon-ticket2:before {
  content: "\e82c";
}
.icon-rss:before {
  content: "\e82d";
}
.icon-signal:before {
  content: "\e82e";
}
.icon-thermometer:before {
  content: "\e82f";
}
.icon-droplets:before {
  content: "\e830";
}
.icon-uniE831:before {
  content: "\e831";
}
.icon-statistics:before {
  content: "\e832";
}
.icon-pie2:before {
  content: "\e833";
}
.icon-bars3:before {
  content: "\e834";
}
.icon-graph:before {
  content: "\e835";
}
.icon-lock3:before {
  content: "\e836";
}
.icon-lock-open:before {
  content: "\e837";
}
.icon-logout:before {
  content: "\e838";
}
.icon-login:before {
  content: "\e839";
}
.icon-checkmark3:before {
  content: "\e83a";
}
.icon-cross:before {
  content: "\e83b";
}
.icon-minus2:before {
  content: "\e83c";
}
.icon-plus2:before {
  content: "\e83d";
}
.icon-cross2:before {
  content: "\e83e";
}
.icon-minus3:before {
  content: "\e83f";
}
.icon-plus3:before {
  content: "\e840";
}
.icon-cross3:before {
  content: "\e841";
}
.icon-minus4:before {
  content: "\e842";
}
.icon-plus4:before {
  content: "\e843";
}
.icon-erase:before {
  content: "\e844";
}
.icon-blocked2:before {
  content: "\e845";
}
.icon-info3:before {
  content: "\e846";
}
.icon-info4:before {
  content: "\e847";
}
.icon-question2:before {
  content: "\e848";
}
.icon-help:before {
  content: "\e849";
}
.icon-warning2:before {
  content: "\e84a";
}
.icon-cycle:before {
  content: "\e84b";
}
.icon-cw:before {
  content: "\e84c";
}
.icon-ccw:before {
  content: "\e84d";
}
.icon-shuffle2:before {
  content: "\e84e";
}
.icon-arrow:before {
  content: "\e84f";
}
.icon-arrow2:before {
  content: "\e850";
}
.icon-retweet:before {
  content: "\e851";
}
.icon-loop4:before {
  content: "\e852";
}
.icon-history2:before {
  content: "\e853";
}
.icon-back:before {
  content: "\e854";
}
.icon-switch2:before {
  content: "\e855";
}
.icon-list3:before {
  content: "\e856";
}
.icon-add-to-list:before {
  content: "\e857";
}
.icon-layout:before {
  content: "\e858";
}
.icon-list4:before {
  content: "\e859";
}
.icon-text:before {
  content: "\e85a";
}
.icon-text2:before {
  content: "\e85b";
}
.icon-document:before {
  content: "\e85c";
}
.icon-docs:before {
  content: "\e85d";
}
.icon-landscape:before {
  content: "\e85e";
}
.icon-pictures:before {
  content: "\e85f";
}
.icon-video:before {
  content: "\e860";
}
.icon-music4:before {
  content: "\e861";
}
.icon-folder2:before {
  content: "\e862";
}
.icon-archive:before {
  content: "\e863";
}
.icon-trash:before {
  content: "\e864";
}
.icon-upload4:before {
  content: "\e865";
}
.icon-download4:before {
  content: "\e866";
}
.icon-disk2:before {
  content: "\e867";
}
.icon-install:before {
  content: "\e868";
}
.icon-cloud2:before {
  content: "\e869";
}
.icon-upload5:before {
  content: "\e86a";
}
.icon-bookmark2:before {
  content: "\e86b";
}
.icon-bookmarks2:before {
  content: "\e86c";
}
.icon-book3:before {
  content: "\e86d";
}
.icon-play4:before {
  content: "\e86e";
}
.icon-pause3:before {
  content: "\e86f";
}
.icon-record:before {
  content: "\e870";
}
.icon-stop3:before {
  content: "\e871";
}
.icon-next2:before {
  content: "\e872";
}
.icon-previous2:before {
  content: "\e873";
}
.icon-first2:before {
  content: "\e874";
}
.icon-last2:before {
  content: "\e875";
}
.icon-resize-enlarge:before {
  content: "\e876";
}
.icon-resize-shrink:before {
  content: "\e877";
}
.icon-volume:before {
  content: "\e878";
}
.icon-sound:before {
  content: "\e879";
}
.icon-mute:before {
  content: "\e87a";
}
.icon-flow-cascade:before {
  content: "\e87b";
}
.icon-flow-branch:before {
  content: "\e87c";
}
.icon-flow-tree:before {
  content: "\e87d";
}
.icon-flow-line:before {
  content: "\e87e";
}
.icon-flow-parallel:before {
  content: "\e87f";
}
.icon-arrow-left4:before {
  content: "\e880";
}
.icon-arrow-down4:before {
  content: "\e881";
}
.icon-arrow-up-upload:before {
  content: "\e882";
}
.icon-arrow-right4:before {
  content: "\e883";
}
.icon-arrow-left5:before {
  content: "\e884";
}
.icon-arrow-down5:before {
  content: "\e885";
}
.icon-arrow-up4:before {
  content: "\e886";
}
.icon-arrow-right5:before {
  content: "\e887";
}
.icon-arrow-left6:before {
  content: "\e888";
}
.icon-arrow-down6:before {
  content: "\e889";
}
.icon-arrow-up5:before {
  content: "\e88a";
}
.icon-arrow-right6:before {
  content: "\e88b";
}
.icon-arrow-left7:before {
  content: "\e88c";
}
.icon-arrow-down7:before {
  content: "\e88d";
}
.icon-arrow-up6:before {
  content: "\e88e";
}
.icon-arrow-right7:before {
  content: "\e88f";
}
.icon-arrow-left8:before {
  content: "\e890";
}
.icon-arrow-down8:before {
  content: "\e891";
}
.icon-arrow-up7:before {
  content: "\e892";
}
.icon-arrow-right8:before {
  content: "\e893";
}
.icon-arrow-left9:before {
  content: "\e894";
}
.icon-arrow-down9:before {
  content: "\e895";
}
.icon-arrow-up8:before {
  content: "\e896";
}
.icon-arrow-right9:before {
  content: "\e897";
}
.icon-arrow-left10:before {
  content: "\e898";
}
.icon-arrow-down10:before {
  content: "\e899";
}
.icon-arrow-up9:before {
  content: "\e89a";
}
.icon-uniE89B:before {
  content: "\e89b";
}
.icon-arrow-left11:before {
  content: "\e89c";
}
.icon-arrow-down11:before {
  content: "\e89d";
}
.icon-arrow-up10:before {
  content: "\e89e";
}
.icon-arrow-right10:before {
  content: "\e89f";
}
.icon-menu3:before {
  content: "\e8a0";
}
.icon-ellipsis:before {
  content: "\e8a1";
}
.icon-dots:before {
  content: "\e8a2";
}
.icon-dot:before {
  content: "\e8a3";
}
.icon-cc:before {
  content: "\e8a4";
}
.icon-cc-by:before {
  content: "\e8a5";
}
.icon-cc-nc:before {
  content: "\e8a6";
}
.icon-cc-nc-eu:before {
  content: "\e8a7";
}
.icon-cc-nc-jp:before {
  content: "\e8a8";
}
.icon-cc-sa:before {
  content: "\e8a9";
}
.icon-cc-nd:before {
  content: "\e8aa";
}
.icon-cc-pd:before {
  content: "\e8ab";
}
.icon-cc-zero:before {
  content: "\e8ac";
}
.icon-cc-share:before {
  content: "\e8ad";
}
.icon-cc-share2:before {
  content: "\e8ae";
}
.icon-danielbruce:before {
  content: "\e8af";
}
.icon-danielbruce2:before {
  content: "\e8b0";
}
.icon-github6:before {
  content: "\e8b1";
}
.icon-github7:before {
  content: "\e8b2";
}
.icon-flickr5:before {
  content: "\e8b3";
}
.icon-flickr6:before {
  content: "\e8b4";
}
.icon-vimeo4:before {
  content: "\e8b5";
}
.icon-vimeo5:before {
  content: "\e8b6";
}
.icon-twitter4:before {
  content: "\e8b7";
}
.icon-twitter5:before {
  content: "\e8b8";
}
.icon-facebook4:before {
  content: "\e8b9";
}
.icon-facebook5:before {
  content: "\e8ba";
}
.icon-facebook6:before {
  content: "\e8bb";
}
.icon-googleplus5:before {
  content: "\e8bc";
}
.icon-googleplus6:before {
  content: "\e8bd";
}
.icon-pinterest3:before {
  content: "\e8be";
}
.icon-pinterest4:before {
  content: "\e8bf";
}
.icon-tumblr3:before {
  content: "\e8c0";
}
.icon-tumblr4:before {
  content: "\e8c1";
}
.icon-linkedin2:before {
  content: "\e8c2";
}
.icon-linkedin3:before {
  content: "\e8c3";
}
.icon-dribbble4:before {
  content: "\e8c4";
}
.icon-dribbble5:before {
  content: "\e8c5";
}
.icon-stumbleupon3:before {
  content: "\e8c6";
}
.icon-stumbleupon4:before {
  content: "\e8c7";
}
.icon-lastfm3:before {
  content: "\e8c8";
}
.icon-lastfm4:before {
  content: "\e8c9";
}
.icon-rdio:before {
  content: "\e8ca";
}
.icon-rdio2:before {
  content: "\e8cb";
}
.icon-spotify:before {
  content: "\e8cc";
}
.icon-spotify2:before {
  content: "\e8cd";
}
.icon-qq:before {
  content: "\e8ce";
}
.icon-instagram2:before {
  content: "\e8cf";
}
.icon-dropbox:before {
  content: "\e8d0";
}
.icon-evernote:before {
  content: "\e8d1";
}
.icon-flattr2:before {
  content: "\e8d2";
}
.icon-skype2:before {
  content: "\e8d3";
}
.icon-skype3:before {
  content: "\e8d4";
}
.icon-renren:before {
  content: "\e8d5";
}
.icon-sina-weibo:before {
  content: "\e8d6";
}
.icon-paypal4:before {
  content: "\e8d7";
}
.icon-picasa:before {
  content: "\e8d8";
}
.icon-soundcloud3:before {
  content: "\e8d9";
}
.icon-mixi:before {
  content: "\e8da";
}
.icon-behance:before {
  content: "\e8db";
}
.icon-circles:before {
  content: "\e8dc";
}
.icon-vk:before {
  content: "\e8dd";
}
.icon-smashing:before {
  content: "\e8de";
}
