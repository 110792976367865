@charset 'UTF-8';

// Import Foundation Override Declarations
@import "foundation_settings_override";


// Import Foundation

@charset 'UTF-8';

// Import Foundation Override Declarations
@import "foundation_settings_override";

// Import Foundation
//@import "node_modules/zurb-foundation-5/scss/foundation/components/accordion";
@import "node_modules/zurb-foundation-5/scss/foundation/components/alert-boxes";
@import "node_modules/zurb-foundation-5/scss/foundation/components/block-grid";
@import "node_modules/zurb-foundation-5/scss/foundation/components/breadcrumbs";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/button-groups";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/buttons";
@import "node_modules/zurb-foundation-5/scss/foundation/components/clearing";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/dropdown";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/dropdown-buttons";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/flex-video";
@import "node_modules/zurb-foundation-5/scss/foundation/components/forms";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/icon-bar";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/inline-lists";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/joyride";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/keystrokes";
@import "node_modules/zurb-foundation-5/scss/foundation/components/labels";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/magellan";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/orbit";
@import "node_modules/zurb-foundation-5/scss/foundation/components/pagination";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/panels";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/pricing-tables";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/progress-bars";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/range-slider";
@import "node_modules/zurb-foundation-5/scss/foundation/components/reveal";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/side-nav";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/split-buttons";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/sub-nav";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/switches";
@import "node_modules/zurb-foundation-5/scss/foundation/components/tables";
@import "node_modules/zurb-foundation-5/scss/foundation/components/tabs";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/thumbs";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/tooltips";
@import "node_modules/zurb-foundation-5/scss/foundation/components/top-bar";
@import "node_modules/zurb-foundation-5/scss/foundation/components/type";
//@import "node_modules/zurb-foundation-5/scss/foundation/components/offcanvas";
@import "node_modules/zurb-foundation-5/scss/foundation/components/visibility";

// Support for non-Foundation Original components
@import "icomoon";
@import "partials/legacy-css";
@import "partials/flash-messages";
@import "partials/colors";
@import "partials/datepicker";

// Import Partials
@import "partials/autocomplete";
@import "partials/buttons";
@import "partials/charts";
@import "partials/forms";
@import "partials/general";
@import "partials/page404";
@import "partials/tables";
@import "partials/top-bar";
@import "partials/select2";
@import "partials/font-awesome";
@import "partials/pagination";

// Location Settings
.location-information {
  .solicitations {
    input[type=radio] {
      margin-bottom: 0;
    }
    .url {
      display: none;
      margin-top: 7px;
      font-size: 0.9333333333rem;
      color: #4d4d4d;
    }
  }
}

// Balance @ partial.dashboard.financials.balance
h4.balance {
  font-size: 2.2em;
  a {
    margin-top: 0 !important;
    vertical-align: text-top;
    padding: 9px 12px !important;
  }
}

// Activate parker @ views.dashboard.parkers.activate_account
.placeholder-wrapper {
  position:relative;
  width:100%;
  height:36px;

  select.placeholder-dropdown {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border:1px solid #ccc;
    background-color:#fff;
    outline: 0;
  }
}