html {
  touch-action: manipulation;
}

body {
  background: url(../images/bg.png);

  .main {
    background: white;
  }
  .content {
    padding-top: 30px !important;
  }
}

.logo-small {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.copyright, .small-copyright {
  color: #fff;
  font-size: 85%;
  text-align: center;
  padding: 20px 0;
  min-height: 80px;

  a, a:link, a:hover, a:active, a:visited {
    color: #f9f9f9;
  }

  a:hover {
    text-decoration: underline;
  }
}

.white-box {
  background: #fff;
  padding: 30px;

  @media #{$medium-up} {
    border-radius: 5px;
  }
}

.locations-map {
  width: 100%;
  height: 500px;
}

[v-cloak] {
  display: block;
}
.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
  display: none;
}
[v-cloak] .v-cloak--block {
  display: block;
}
[v-cloak] .v-cloak--inline {
  display: inline;
}
[v-cloak] .v-cloak--inlineBlock {
  display: inline-block;
}
[v-cloak] .v-cloak--hidden {
  display: none;
}
[v-cloak] .v-cloak--invisible {
  visibility: hidden;
}

[v-cloak].loading-report  {
  padding: 60px;
  text-align: center;
}




/* Layout */
.mt { margin-top:    $base-margin-sm }
.mb { margin-bottom: $base-margin-sm }
.ml { margin-left:   $base-margin-sm }
.mr { margin-right:  $base-margin-sm }

.pt { padding-top:    $base-padding-sm }
.pb { padding-bottom: $base-padding-sm }
.pl { padding-left:   $base-padding-sm }
.pr { padding-right:  $base-padding-sm }
