
.error-msg {
  color: red;
  border-color: red;
  background-color: #f3b6b6;
  padding: 5px 10px;
}

.success-msg {
  color: green;
  border-color: green;
  background-color: #bbdfc9;
  padding: 5px 10px;
}

.warning-msg {
  color: orange;
  border-color: orange;
  background-color: #ffed99;
  padding: 5px 10px;
}


//Overdue Payments
.dashboard .view h3.error {
  color: red;
  border-color: red;
}

section.error {
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
}


.flash-messages {
  text-align: center;
}


.msg {
  margin-top: -8px;
  margin-bottom: 8px;
  padding: 12px;
  float: left;
  width: 100%;
}