//Tables Styling
table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}

//Responsive Tables
.responsive-table {

  width: 100%;
  overflow-x: auto;

  table.xlarge {
    min-width: #{upper-bound($large-range)/1.2};
  }
  table.large {
    min-width: #{lower-bound($large-range)};
  }
  table.medium {
    min-width: #{lower-bound($medium-range)};
  }
  table.small {
    min-width: #{lower-bound($medium-range)/1.5};
  }

}

tr.clickable-row:hover {
  background-color: #D9E7FA;
  cursor: pointer;
}

.sort i {
  display: inherit;
  height: 6px;
}

table {
  tr.invoice-row.overdue {
    background: #EA8484;
  }
  tr.invoice-row.overdue:hover {
    background: #DA8693;
  }

  tr.invoice-row.due {
    background: #C9EED7;
  }
  tr.invoice-row.due:hover {
    background: #B7E7DE;
  }

  tr.error {
    background: #EA8484;
  }
  tr.error:hover {
    background: #DA8693;
  }

  tr.payready {
    background: #C9EED7;
  }
  tr.payready:hover {
    background: #B7E7DE;
  }
}

table.parker-cost-breakdown tr td {
  line-height: 0.9rem;
}

table.parker-cost-breakdown tr:nth-of-type(even),
table.parker-cost-breakdown tr:nth-of-type(odd) {
  background-color: transparent !important;
}

table.parker-cost-breakdown tr.subtotal {
  background-color:#F1F1F1 !important;
}

table tr.contribution-due td:first-of-type {
  font-style: italic;
  padding-left:20px;
}

table tr.subtotal td {
  padding-top:0;
  padding-bottom:0;
}

table tr.subtotal h2,
table tr.subtotal h3,
table tr.subtotal h4 {
  margin-top:0.4rem !important;
  margin-bottom:0.4rem !important;
}

//Paginations
ul.pagination li.current a,
ul.pagination li.current button {
  background: $green;
  color: #FFF;
  font-weight: 700;
  cursor: default;
}

ul.pagination li.current a:focus,
ul.pagination li.current a:hover,
ul.pagination li.current button:focus,
ul.pagination li.current button:hover {
  background: darken($green, 10%);
}
