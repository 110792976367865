.button {
  &.tiny {
    margin: 1px !important;
  }

  &.narrow {
    margin-top:10px;
    margin-bottom:0px;
  }
}

.inline-medium-button {
  margin: -20px 0;

  .button {
    padding: 8px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
}







/* Tablet */
@media screen and (max-width: 750px) {
    .button {
      &.narrow {
        padding: 15px 10px !important;
      }
    }
}