.form-field {
  clear: both;
  overflow: hidden;
}

.form-field button.left {
  float: left;
  margin-right: 30px;
}

.form-field label.remember {
  margin-top: 15px;
}

input.margin-none {
  margin: 0;
}

.tight-radio {
  input[type=radio] {
    margin-bottom: 0;
  }
}

input.inline-date {
  border: none;
  border-bottom: 1px dotted black;
  width: 100px;
  box-shadow: none;
  padding: 0px;
  margin: 0;
  background: none !important;
  line-height: initial;
  height: 20px;
  font-weight: bolder;
  display: inline;
  font-size: initial;
}

input.inline-date:focus {
  background: #F3E8DE !important;
  border-bottom: 1px solid black;
}


