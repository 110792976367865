.hidden {
  display: none;
}

.dashboard .view h3 {
  font-size: 1.2rem;
  font-weight: normal;
  margin: 1rem 0 1rem 0;
  padding: .6rem 0 .6rem 0;
  border-bottom: 1px solid #bbb;
  float: left;
  width: 100%;
}

.dashboard {
  .view.section-borders section,
  .view.parkers section,
  .view.parking-accounts section {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 3rem;
  }

  .view {
    section {
      width: 100%;
      padding: 2%;
    }

    h2,
    h2 a {
      font-size: 1.6rem;
      font-weight: normal;
      margin: 0 0 .5rem 0;
    }
  }

}

.group-summary {
  section.no-padd {
    padding: 0;
  }
}

//Draws the gray rounded-border
//for the "Active, Pending, etc... Parkers"
.bordered {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 3rem;
}


@media #{$medium-up} {
  section.parking-account {
    .parking-account-location {
      float: left;
    }
    .parking-account-info {
      float: right;
      text-align: right;
    }
  }
}
