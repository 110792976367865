@import "node_modules/zurb-foundation-5/scss/foundation/functions";

/************************************
  Fonts Overrides
************************************/
// Adjust the base font-size slightly.
$base-font-size: 15px;
$rem-base: $base-font-size;


/************************************
  Forms Overrides
************************************/
$input-include-glowing-effect: false;



/************************************
  Topbar Overrides
************************************/
//$topbar-height: 5.45rem;
$topbar-breakpoint: 750px; // Change to 9999px for always mobile layout
$topbar-media-query: "screen and (min-width:#{$topbar-breakpoint})";
$my-topbar-media-query: "#{$topbar-media-query}";



/************************************
  Buttons Overrides
************************************/
$button-tny: rem-calc(6);


/************************************
  Grid Overrides
************************************/
$row-width: rem-calc(1200);



$input-disabled-bg: #ececec;


$base-margin:    16px;
$base-margin-lg: $base-margin + 8px;
$base-margin-sm: $base-margin - 8px;

$base-padding:    16px;
$base-padding-lg: $base-padding + 8px;
$base-padding-sm: $base-padding - 8px;