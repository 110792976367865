

.dashboard header nav.top-bar  {
  display: block;
  width: 100%;
  min-height: 90px;
  float: none;
}

.top-bar {
  background: #333 url('../images/bg.png') top left;
  .title-area {
    min-height: 90px;
  }
  .logo {
    height: 60px;
    width: auto;

    margin-top: 13px;
  }
}

.top-bar-section {
  z-index: 50;
  ul.main-nav-ul {
    margin: 0px 0;
  }
  .title-area {
    margin: 20px 0;
  }
}

#nav-user-role-summary {
  margin-top: -17px;
  line-height: 17px;
  font-weight: 200;
  font-size: 14px;
  color: #ffde0e;
}

#nav-username {
  color: #ffde0e;
}

@media #{$my-topbar-media-query} {

  .top-bar-section {
    li:not(.has-form) a:not(.button) {
      background: #333 url('../images/bg.png') top left;
    }
    li.active:not(.has-form) a:not(.button) {
      background: #555;
    }
    li:not(.has-form) a:not(.button):hover {
      text-decoration: none;
    }
  }

  .top-bar-section {
    ul.main-nav-ul {
      margin: 20px 0;
    }
    .right li .dropdown {
      padding-top: 22px;
      background: #333 !important;
    }
  }

  #nav-user-role-summary {
    margin-top: -15px;
    padding-bottom: 10px;
  }
}