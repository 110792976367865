ul.pagination li {
  color: #999999;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;
  margin-left: 0.175rem;

  &.active {
    padding: 0.0666666667rem 0.6666666667rem 0.0666666667rem;
    background: rgba(76, 76, 76, 0.1);
  }
}